import { Suspense, useState } from "react";
import Search from "../../assets/images/search.png";
import Logout from "../../assets/images/logout.png";
import Refresh from "../../assets/images/refresh.png";
// import Schedule from "../../assets/images/schedule.png";
import Home from "./home";
import BookingModule from "./Bookings";
import Lounge from "./Lounge";
import Sales from "./Sales";
import Cafe from "./Cafe";
import AbortModal from "./AbortModal";
import { useLoggedIn, useLogout } from "../../hooks";
import {
  clearOrder,
  createAdminAction,
  getItems,
  getShowtimes,
  setLoyaltyData,
  setOfflineRestriction,
} from "../../redux/slices";
import { useDispatch, useSelector } from "react-redux";
import LogoutError from "./logoutErrorModal";
import ModeModal from "./modeModal";
import OfflineRestrictionModal from "./offlineRestrictionModal";
import AuthReprintModal from "./AuthReprintModal";
import OrderProcessingModal from "./home/OrderProcessingModal";
import AllSalesByUser from "./AllSalesByUser";
import Arcade from "./arcade";
import ComingSoon from "../UI/ComingSoon";
import FetchErrorModal from "./FetchErrorModal";
import InScreenModal from "./InScreenOrders/InScreenOrdersModal";
import LoyaltyReload from "./LoyaltyReload";
import ItemSearch from "./ItemSearch";
import AdminPanel from "./AdminPanel";
import { useTranslation } from "react-i18next";

const PosModule = () => {
  const { t, i18n } = useTranslation(["POS"]);

  const [module, setModule] = useState("home");
  const [isOpen, setIsOpen] = useState(false);
  const [logoutError, setLogoutError] = useState(false);
  const [salesModal, setSalesModal] = useState(false);
  const [AllSalesByUserModal, setAllSalesByUserModal] = useState(false);
  const [showModeModal, setShowModeModal] = useState(false);
  const [showReprintModal, setShowReprintModal] = useState(false);
  const [showInScreenModal, setShowInScreenModal] = useState(false);
  const [showLoyaltyReloadModal, setShowLoyaltyReloadModal] = useState(false);

  const { logout } = useLogout();
  const { orderItems } = useSelector((state) => state.orders);
  const { userData } = useSelector((state) => state.profile);
  const { offlineMode, offlineModeRestriction } = useSelector(
    (state) => state.profile
  );
  const dispatch = useDispatch();
  let offlineOrders = JSON.parse(localStorage.getItem("offlineOrders"));

  const BtnDivs = (props) => {
    return (
      <div
        className={`w-[15%] rounded-md flex text-center items-center justify-center ${props.bg} ${props.txt} ${props.addStyle}`}
        onClick={props.onClick}
      >
        {props.children}{" "}
      </div>
    );
  };
  const IconDivs = (props) => {
    return (
      <div
        className={` flex items-center justify-center w-[33%] h-[100%] ${props.addStyle} `}
      >
        {props.children}
      </div>
    );
  };
  const ActionDivs = (props) => {
    return (
      <div
        className={` w-[12.5%] flex items-center justify-center border-r-2 border-primary ${props.addStyle}`}
        onClick={props.onClick}
      >
        {props.children}
      </div>
    );
  };

  const logoffClickHandler = () => {
    let heldOrders = JSON.parse(localStorage.getItem("heldOrders"));
    let offlineOrders = JSON.parse(localStorage.getItem("offlineOrders"));

    if (offlineMode) {
      return dispatch(setOfflineRestriction(true));
    }
    if (heldOrders?.length > 0) {
      return setLogoutError(t("errors.held"));
    }
    if (offlineOrders?.length > 0) {
      return setLogoutError(t("errors.offline"));
    }
    if (orderItems?.length > 0) {
      return setLogoutError(t("errors.order"));
    } else {
      return (() => {
        logout();
        localStorage.clear();
      })();
    }
  };

  const refreshClickHandler = () => {
    if (offlineMode) {
      return dispatch(setOfflineRestriction(true));
    } else {
      dispatch(getItems());
      dispatch(getShowtimes());
      dispatch(setLoyaltyData({}));
    }
  };

  return (
    <div className=" w-3/4">
      <div className=" h-[82%]">
        {module === "home" && <Home />}
        {module === "arcade" && (
          <Suspense fallback={"Loading...."}>
            <Arcade />
          </Suspense>
        )}
        {module === "lounge" && (
          <Suspense fallback={"Loading...."}>
            {" "}
            <Lounge />{" "}
          </Suspense>
        )}
        {module === "cafe" && (
          <Suspense fallback={"Loading...."}>
            {" "}
            <Cafe />{" "}
          </Suspense>
        )}
        {module === "bookings" && (
          <Suspense fallback={"Loading...."}>
            {" "}
            <BookingModule />{" "}
          </Suspense>
        )}
        {module === "itemSearch" && (
          <Suspense fallback={"Loading...."}>
            {" "}
            <ItemSearch />{" "}
          </Suspense>
        )}
        {module === "adminPanel" && (
          <Suspense fallback={"Loading...."}>
            {" "}
            <AdminPanel />{" "}
          </Suspense>
        )}
        {(module === "giftcard" ||
          module === "foodease" ||
          module === "in-screen orders") && (
          <Suspense fallback={"Loading...."}>
            {" "}
            <ComingSoon />{" "}
          </Suspense>
        )}
        {/*{module === "foodease" && <FoodeaseTable />}*/}
      </div>
      <div className="h-[18%] p-1">
        <div className="h-[40%] flex justify-between p-1">
          <BtnDivs
            bg={"bg-primary"}
            txt={"text-white"}
            onClick={() => {
              setModule("home");
            }}
          >
            {t("sections.a")}
          </BtnDivs>
          <BtnDivs
            bg={"bg-[#B4E8E8]"}
            onClick={() => {
              setModule("lounge");
            }}
          >
            {t("sections.b")}
          </BtnDivs>

          <BtnDivs
            bg={"bg-[#C9BEE2]"}
            onClick={() => {
              setModule("cafe");
            }}
          >
            {t("sections.c")}
          </BtnDivs>
          <BtnDivs
            bg={"bg-[#AEADED]"}
            onClick={() => {
              setModule("arcade");
            }}
          >
            {t("sections.d")}
          </BtnDivs>

          {/* <BtnDivs
            bg={"bg-[#00C170]"}
            txt={"text-[#FFCB00]"}
            onClick={() => {
              setModule("foodease");
            }}
          >
            {t("sections.e")}
          </BtnDivs> */}
          <BtnDivs
            bg={"bg-[#B4E8E8]"}
            txt={"text-primary"}
            onClick={() => {
              if (offlineMode) {
                return dispatch(setOfflineRestriction(true));
              } else {
                setShowLoyaltyReloadModal(true);
              }
            }}
          >
            {t("sections.f")}
          </BtnDivs>
          <BtnDivs
            bg={"bg-[#C9BEE2]"}
            addStyle={" shadow-md border  "}
            onClick={() => {
              setModule("itemSearch");
            }}
          >
            <IconDivs>
              <img src={Search} alt="search" />
            </IconDivs>{" "}
            <span> {t("sections.g")}</span>
            {/* <IconDivs addStyle={"border-x-2 border-primary"}>
              <img src={Barcode} alt="barcode" />
            </IconDivs>
            <IconDivs>
              <img src={Settings} alt="settings" />
            </IconDivs> */}
          </BtnDivs>
        </div>
        <div className="h-[60%] shadow-md border-2 flex rounded-md py-1 ">
          <ActionDivs>
            <button onClick={logoffClickHandler}>
              <div className=" flex justify-center">
                <img src={Logout} alt="logout" />
              </div>
              <p>{t("sections.i")}</p>
            </button>
          </ActionDivs>
          <ActionDivs>
            <button
              onClick={() => {
                if (userData.roleName === "POSUser") {
                  offlineMode
                    ? dispatch(setOfflineRestriction(true))
                    : setIsOpen(true);
                } else {
                  if (!offlineMode) {
                    dispatch(
                      createAdminAction({
                        doneById: userData.userNumber,
                        details: null,
                        action: 0,
                      })
                    );
                    dispatch(clearOrder());
                  } else {
                    dispatch(setOfflineRestriction(true));
                  }
                }
              }}
            >
              <p className="text-center text-red-600 font-bold">X</p>
              <p>{t("sections.j")}</p>
            </button>
          </ActionDivs>
          <ActionDivs>
            <button onClick={refreshClickHandler}>
              <div className=" flex justify-center">
                <img src={Refresh} alt="refresh" />
              </div>
              <p>{t("sections.k")}</p>
            </button>
          </ActionDivs>
          <ActionDivs
            onClick={() => {
              offlineMode
                ? dispatch(setOfflineRestriction(true))
                : setSalesModal(true);
            }}
          >
            <p className="text-center">{t("sections.l")}</p>
          </ActionDivs>
          <ActionDivs
            onClick={() => {
              offlineMode
                ? dispatch(setOfflineRestriction(true))
                : setAllSalesByUserModal(true);
            }}
          >
            <p className="text-center">{t("sections.m")}</p>
          </ActionDivs>
          {/* <ActionDivs
            onClick={() => {
              offlineMode
                ? dispatch(setOfflineRestriction(true))
                : setShowInScreenModal(true);
            }}
          >
            <div
              className={`${INSCount > 0 &&
                "motion-safe:animate-bounce text-red-500 font-bold"}`}
            >
              <p className="text-center px-2  ">{t("sections.p")}</p>
              <p className="text-center"> {INSCount}</p>
            </div>
          </ActionDivs> */}
          <ActionDivs
            onClick={() => {
              offlineMode
                ? dispatch(setOfflineRestriction(true))
                : setModule("bookings");
            }}
          >
            <p className="text-center">{t("sections.n")}</p>
          </ActionDivs>
          <ActionDivs
            onClick={() => {
              setShowModeModal(true);
            }}
          >
            <div>
              <p className="text-center">{t("sections.o")}</p>
              {offlineOrders?.length > 0 && (
                <p className="text-[11px] text-center">
                  {t("pending")}:{" "}
                  <span className="font-bold">
                    {offlineOrders?.length || 0}
                  </span>
                </p>
              )}
            </div>
          </ActionDivs>

          {/* <ActionDivs
            onClick={() => {
              offlineMode
                ? dispatch(setOfflineRestriction(true))
                : setShowInScreenModal(true);
            }}
          >
            <div className="motion-safe:animate-bounce text-red-500 font-bold">
            
              <p className="text-center px-2  ">In-Screen Orders</p>
              <p className="text-center"> (35)</p>
            </div>
          > */}
          {/* <div className="motion-safe:animate-bounce text-red-500 font-bold"> */}
          {/* <div> */}
          {/* was previously get last order */}
          {/* <p className="text-center px-2  ">{t("sections.p")}</p> */}
          {/* <p className="text-center"> (35)</p> */}
          {/* </div>
          </ActionDivs> */}

          <ActionDivs
            addStyle={"border-none"}
            onClick={() => {
              setModule("adminPanel");
            }}
          >
            <p className="text-center">{t("sections.q")}</p>
          </ActionDivs>
        </div>
      </div>
      <AbortModal
        openStatus={isOpen}
        closeFunc={() => {
          setIsOpen(false);
        }}
      />
      <LogoutError
        openStatus={!!logoutError}
        closeFunc={() => {
          setLogoutError(false);
        }}
        logoutError={logoutError}
      />
      <ModeModal
        openStatus={showModeModal}
        closeFunc={() => {
          setShowModeModal(false);
        }}
      />

      <AuthReprintModal
        openStatus={showReprintModal}
        closeFunc={() => {
          setShowReprintModal(false);
        }}
      />
      {offlineModeRestriction && <OfflineRestrictionModal />}
      <FetchErrorModal />
      {salesModal && (
        <Sales
          openStatus={salesModal}
          closeFunc={() => {
            setSalesModal(false);
          }}
        />
      )}
      {AllSalesByUserModal && (
        <AllSalesByUser
          openStatus={AllSalesByUserModal}
          closeFunc={() => {
            setAllSalesByUserModal(false);
          }}
        />
      )}
      {showInScreenModal && (
        <InScreenModal
          openStatus={showInScreenModal}
          closeFunc={() => {
            setShowInScreenModal(false);
          }}
        />
      )}
      {showLoyaltyReloadModal && (
        <LoyaltyReload
          openStatus={showLoyaltyReloadModal}
          closeFunc={() => {
            setShowLoyaltyReloadModal(false);
          }}
        />
      )}
      <OrderProcessingModal />
    </div>
  );
};
export default PosModule;
