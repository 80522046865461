import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  addToOrder,
  setOfflineRestriction,
  getFilmDetails,
} from "../../../redux/slices";
import { STATUS } from "../../../utils/constants";
import UpSign from "../../../assets/images/up.png";
import DownSign from "../../../assets/images/down.png";
import Schedule from "../../../assets/images/schedule.png";
import { useSelector } from "react-redux";
import ShowtimeDateModal from "./ShowtimeDateModal";
import TicketSaleAuthModal from "./TicketSaleAuthModal";
import ConcessionModal from "./concessionModal";
import VoucherModal from "./VoucherModal";
import FilmDetailsModal from "./FilmDetailsModal";
import SeatAllocationModal from "./SeatAllocationModal";
import { ListByShows, ViewsModal, ListByScreens } from "./TicketViews";
import { ListByFilms } from "./TicketViews/ListByFilms";
import QtyModalForAllocation from "./QtyModalForAllocation";
import { changeQuantityForPkg } from "../../../redux/slices";
import { useTranslation } from "react-i18next";

const clamp = (num, min = 0, max = 10) => Math.min(Math.max(num, min), max);

const Ticket = () => {
  const { t } = useTranslation(["Home"]);

  const dispatch = useDispatch();
  const { showtimesList, status } = useSelector((state) => state.showtimes);
  const { offlineMode, cinemaSettings } = useSelector((state) => state.profile);
  const { orderItems } = useSelector((state) => state.orders);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPage2, setCurrentPage2] = useState(0);
  const [openShowtimeModal, setOpenShowtimeModal] = useState(false);
  const [freeTicketData, setFreeTicketData] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [subItems, setSubItems] = useState([]);
  const [openVoucherModal, setOpenVoucherModal] = useState(false);
  const [voucherTicketData, setVoucherTicketData] = useState({});
  const [showFilmDetails, setShowFilmDetails] = useState(false);
  const [ticketDataForAllocation, setTicketDataForAllocation] = useState(false);
  const [listByFilm, setListByFilm] = useState(false);
  const [allocationData, setAllocationData] = useState(false);
  const [callBack, setCallBack] = useState(null);
  const [selectedView, setSelectedView] = useState("listByShows");
  const [showViewsModal, setShowViewsModal] = useState(false);

  const NewTicketData = showtimesList?.result?.map((data, index) => {
    return { ...data, canModify: true, key: index };
  });

  const showtimeByFilm = NewTicketData?.filter((showtime, i, ar) => {
    return (
      ar
        ?.map((x) => {
          return x.filmId;
        })
        .indexOf(showtime?.filmId) === i
    );
  });

  let maxPage = Math.ceil(NewTicketData?.length / 6);

  const prevPage = () => {
    let newPage = clamp(currentPage - 1, 0, maxPage);

    setCurrentPage(newPage);
  };
  const nextPage = () => {
    let newPage = clamp(currentPage + 1, 0, maxPage);
    let tempStart = newPage * 6;
    let tempEnd = tempStart + 6;
    if (NewTicketData?.slice(tempStart, tempEnd)?.length < 1) {
      return;
    }

    setCurrentPage(newPage);
  };
  const start = currentPage * 6;
  const end = start + 6;

  let maxPage2 = Math.ceil(showtimeByFilm?.length / 5);

  const prevPage2 = () => {
    let newPage2 = clamp(currentPage2 - 1, 0, maxPage2);

    setCurrentPage2(newPage2);
  };
  const nextPage2 = () => {
    let newPage2 = clamp(currentPage2 + 1, 0, maxPage2);

    let tempStart2 = newPage2 * 5;
    let tempEnd2 = tempStart2 + 5;
    if (showtimeByFilm?.slice(tempStart2, tempEnd2)?.length < 1) {
      return;
    }

    setCurrentPage2(newPage2);
  };
  const start2 = currentPage2 * 5;
  const end2 = start2 + 5;

  const packageTicketHandler = (x) => {
    // function to handle single items in the package
    const handleSingleItemsInPkg = (numOfSeats) => {
      const formatItems = x?.ticketPackage?.items?.map((data) => {
        return {
          ...data,
          id: data.itemId,
          price: 0,
          priceInPackage: data.price,
          orderQuantity: numOfSeats
            ? data.quantity * numOfSeats
            : data.quantity,
          //using this base quantity to preserve the quantity of the package so if its an item in the package has a quantity of
          // 2 when the package is added to the cart again it is incremented by 2 not the default one
          baseQuantity: numOfSeats ? data.quantity * numOfSeats : data.quantity,
          packageTicketId: x.ticketId,
          //this id would serve as a unique identifier for all the children of a package ticket so if any of them gets deleted
          //the siblings would be deleted too
          idForDelete: data.id,
          //unique identifier to modify quantity
          pkgShowtimeId: x?.showtimeId,
        };
      });

      formatItems.forEach((item) => {
        dispatch(addToOrder(item));
      });
    };

    //function to handle tickets in a package

    const handleTicketsInPkg = () => {
      const formatTickets = x?.ticketPackage?.tickets.map((data) => {
        return {
          id: data.ticketId,
          availableQuantity: x.totalSeats - x.seatsSold,
          name: x.film,
          price: 0,
          priceInPackage: data.price,
          totalPackagePrice: x.price,
          canModify: false,
          showtimeId: x.showtimeId,
          packageTicketId: x.ticketId,
          ticketId: x.ticketId,
          ticketName: x.ticketName,

          //using this base quantity to preserve the quantity of the package so if its an item in the package has a quantity of
          // 2 when the package is added to the cart again it is incremented by 2 not the default one
          baseQuantity: data.quantity,
          orderQuantity: data.quantity,
          //this id would serve as a unique identifier for all the children of a package ticket so if any of them gets deleted
          //the siblings would be deleted too
          idForDelete: data.id,
          priceCardTicketId: x.id,
          screen: x.screen || t("ticket.empty.screen"),
          startTime: x.startTime,
          showtimeDate: new Date(x.startTime).toDateString(),
          time:
            new Date(x.startTime).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            }) +
            "-" +
            new Date(x.endTime).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            }),
        };
      });

      formatTickets.forEach((ticket) => {
        dispatch(addToOrder(ticket));
      });
    };

    //Function to handle parent items in the Package

    const handleParentItemsInPkg = (numOfSeats = 1) => {
      if (x?.ticketPackage?.itemParents.length > 0) {
        const ticketsArray = [];
        //we first get the parent items with quantity of one
        let parentItemArray = x?.ticketPackage?.itemParents.filter((data) => {
          return data.quantity === 1;
        });

        if (numOfSeats === 1) {
          parentItemArray.forEach((tx) => {
            ticketsArray.push(tx);
          });
        } else {
          parentItemArray.forEach((parent) => {
            const quantityArray = Array.from({
              length: numOfSeats,
            });

            quantityArray.forEach((_, i) => {
              ticketsArray.push(parent);
            });
          });
        }

        //then we get the parent items where the quantity is more than one
        const parentItemArray2 = x?.ticketPackage?.itemParents.filter(
          (data) => {
            return data.quantity !== 1;
          }
        );

        //then we try to create multiple instances of parentItemArray2 based on the quantity

        parentItemArray2.forEach((parent) => {
          const quantityArray = Array.from({
            length: parent.quantity * numOfSeats,
          });

          quantityArray.forEach((_, i) => {
            ticketsArray.push(parent);
          });
        });

        const finalArray = ticketsArray.map((p) => {
          return p.items.map((item) => {
            return {
              ...item,
              price: 0,
              priceInPackage: item.price,
              id: item.itemId,
              packageTicketId: x.ticketId,
              itemParentId: p.id,
              pkgShowtimeId: x?.showtimeId,
              //this id would serve as a unique identifier for all the children of a package ticket so if any of them gets deleted
              //the siblings would be deleted too
              idForDelete: item.id,
            };
          });
        });

        setIsOpen(true);
        setSubItems(finalArray);
        setCallBack({ func: x.callback });
      } else {
        x.callback();
      }
    };

    //this helps to cater for cases when the function is called and numOfSeats is passed which means there is seat allocation
    if (!x.numOfSeats) {
      handleSingleItemsInPkg();
      handleTicketsInPkg();
      handleParentItemsInPkg();
      return;
    } else {
      handleSingleItemsInPkg(x.numOfSeats);
      handleParentItemsInPkg(x.numOfSeats);
    }
  };

  const addTicketToOrder = ({
    pricecard,
    item,
    numOfSeats,
    selectedSeats,
    callback = null,
  }) => {
    if (pricecard.ticketGroup === "Voucher") {
      setOpenVoucherModal(true);
      setVoucherTicketData({
        id: pricecard.ticketId,
        availableQuantity: item.totalSeats - item.seatsSold,
        name: item.film,
        price: pricecard.price,
        canModify: false,
        showtimeId: item.id,
        ticketId: pricecard.ticketId,
        priceCardTicketId: pricecard.id,
        screen: item.screen || t("ticket.empty.screen"),
        startTime: item.startTime,
        showtimeDate: new Date(item.startTime).toDateString(),
        time:
          new Date(item.startTime).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }) +
          "-" +
          new Date(item.endTime).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }),
      });
      return;
    }

    //if its a package ticket it sends it to the package ticket handler
    if (pricecard.ticketPackageId) {
      dispatch(
        addToOrder({
          id: pricecard.id,
          price: pricecard.price,
          name: pricecard.shortName,
          isPackage: true,
          //this id would serve as a unique identifier for all the children of a package ticket so if any of them gets deleted
          //the siblings would be deleted too
          idForDelete: pricecard.id,
          //unique identifier to modify quantity
          pkgShowtimeId: item.id,
          availableQuantity: item.totalSeats - item.seatsSold,
          packageTicketData: !numOfSeats ? { item, pricecard } : null,
          numOfSeats,
        })
      );
      packageTicketHandler({
        ...pricecard,
        totalSeats: item.totalSeats,
        seatsSold: item.seatsSold,
        film: item.film,
        showtimeId: item.id,
        screen: item.screen || t("ticket.empty.screen"),
        startTime: item.startTime,
        endTime: item.endTime,
        numOfSeats,
        callback,
      });
      return;
    }
    //if its a standard ticket with a price i.e its not a comp ticket
    if (pricecard.price !== 0) {
      const data = {
        id: pricecard.ticketId,
        availableQuantity: item.totalSeats - item.seatsSold,
        name: item.film,
        // Made price, priceInPackage and canModify here dynamic because of Canal Olympia so when its an open ticket it adds to cart
        // as zero value similar to when its a child of a package but create the order with the actual value
        price: pricecard?.openTicket ? 0 : pricecard.price,
        priceInPackage: pricecard?.openTicket ? pricecard.price : null,
        canModify: !pricecard?.openTicket,
        showtimeId: item.id,
        ticketId: pricecard.ticketId,
        ticketName: pricecard.ticketName,
        priceCardTicketId: pricecard.id,
        screen: item.screen || t("ticket.empty.screen"),
        startTime: item.startTime,
        showtimeDate: new Date(item.startTime).toDateString(),
        time:
          new Date(item.startTime).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }) +
          "-" +
          new Date(item.endTime).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }),
      };

      // check for seat allocation
      if (!cinemaSettings?.seatAllocation || !pricecard?.seatClassId) {
        dispatch(addToOrder(data));
      } else {
        selectedSeats.forEach((seat) => {
          dispatch(
            addToOrder({
              ...data,
              canModify: false,
              seatLayoutId: seat.seatLayoutId,
              seatNumber: seat.seatNumber,
              seatIdentifier: seat.identifier,
              rowName: seat.rowName,
            })
          );
        });
      }

      return;
    }
    //check to require validation for the sale of free tickets
    if (pricecard.price === 0 && !offlineMode) {
      setFreeTicketData({
        id: pricecard.ticketId,
        availableQuantity: item.totalSeats - item.seatsSold,
        name: item.film,
        price: pricecard.price,
        canModify: true,
        showtimeId: item.id,
        ticketId: pricecard.ticketId,
        priceCardTicketId: pricecard.id,
        screen: item.screen || t("ticket.empty.screen"),
        startTime: item.startTime,
        showtimeDate: new Date(item.startTime).toDateString(),
        time:
          new Date(item.startTime).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }) +
          "-" +
          new Date(item.endTime).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }),
      });
      return;
    }
    if (pricecard.price === 0 && offlineMode) {
      return dispatch(setOfflineRestriction(true));
    }
  };

  return (
    <>
      {status === STATUS.PENDING && (
        <div className=" bg-primary w-[100%] h-[100%] flex items-center justify-center ">
          {" "}
          <p className=" text-[white] ">{t("loading")}</p>
        </div>
      )}
      {status === STATUS.IDLE && !NewTicketData && (
        <div className=" bg-primary w-[100%] h-[100%] flex items-center justify-center ">
          {" "}
          <p className=" text-[white] ">{t("refresh")}</p>
        </div>
      )}

      {status !== STATUS.PENDING && NewTicketData?.length < 1 && (
        <div className="flex bg-primary h-[100%]">
          <div className=" bg-primary w-[93%] h-[100%] flex items-center justify-center ">
            <p className=" text-[white] ">{t("ticket.empty.showtime")}</p>
          </div>
          <div className="w-[7%] bg-white px-1 ">
            <div
              className=" border-2 h-[50px] rounded mb-2 bg-no-repeat bg-[50%] bg-center"
              style={{
                backgroundImage: `url(${Schedule})`,
              }}
              onClick={() => {
                offlineMode
                  ? dispatch(setOfflineRestriction(true))
                  : setOpenShowtimeModal(true);
              }}
            ></div>
          </div>
        </div>
      )}
      {status !== STATUS.PENDING && NewTicketData?.length > 0 && (
        <div className="flex bg-primary h-[100%] font-bold">
          {selectedView === "listByShows" && (
            <ListByShows
              NewTicketData={NewTicketData}
              setShowFilmDetails={setShowFilmDetails}
              setTicketDataForAllocation={setTicketDataForAllocation}
              addTicketToOrder={addTicketToOrder}
              start={start}
              end={end}
            />
          )}
          {selectedView === "listByScreens" && (
            <ListByScreens
              NewTicketData={NewTicketData}
              setShowFilmDetails={setShowFilmDetails}
              setTicketDataForAllocation={setTicketDataForAllocation}
              addTicketToOrder={addTicketToOrder}
              start={start}
              end={end}
            />
          )}
          {selectedView === "listByFilms" && (
            <ListByFilms
              NewTicketData={NewTicketData}
              addTicketToOrder={addTicketToOrder}
              start={start2}
              end={end2}
              setTicketDataForAllocation={setTicketDataForAllocation}
            />
          )}

          <div className="w-[7%] bg-white px-1 ">
            <div
              className=" border-2 h-[50px] rounded mb-2 text-primary flex justify-center items-center text-[12px]"
              onClick={() => {
                setShowViewsModal(true);
              }}
            >
              {" "}
              <p className="text-center">{t("ticket.display")}</p>
            </div>
            <div
              className=" border-2 h-[50px] rounded mb-2 bg-no-repeat bg-[50%] bg-center"
              style={{
                backgroundImage: `url(${UpSign})`,
              }}
              onClick={() => {
                if (selectedView === "listByShows") {
                  prevPage();
                }
                if (selectedView === "listByFilms") {
                  prevPage2();
                }
              }}
            ></div>
            {selectedView === "listByScreens" && (
              <div
                className=" border-2 h-[50px] rounded mb-2 text-primary flex justify-center items-center text-[12px]"
                onClick={() => {
                  // setShowViewsModal(true);
                }}
              >
                {" "}
                <p className="text-center">{t("ticket.now")}</p>
              </div>
            )}
            <div
              className=" border-2 h-[50px] rounded mb-2 bg-no-repeat bg-[50%] bg-center "
              style={{
                backgroundImage: `url(${DownSign})`,
              }}
              onClick={() => {
                if (selectedView === "listByShows") {
                  nextPage();
                }
                if (selectedView === "listByFilms") {
                  nextPage2();
                }
              }}
            ></div>
            <div
              className=" border-2 h-[50px] rounded mb-2 bg-no-repeat bg-[50%] bg-center"
              style={{
                backgroundImage: `url(${Schedule})`,
              }}
              onClick={() => {
                offlineMode
                  ? dispatch(setOfflineRestriction(true))
                  : setOpenShowtimeModal(true);
              }}
            ></div>
          </div>
          <ConcessionModal
            openStatus={isOpen}
            closeFunc={() => {
              setIsOpen(false);
            }}
            data={subItems}
            disableClose={true}
            callBack={callBack}
          />
        </div>
      )}

      <ShowtimeDateModal
        openStatus={openShowtimeModal}
        closeFunc={() => {
          setOpenShowtimeModal(false);
        }}
      />
      {/* did not want to use two set of states to both trigger the modal and pass data on the free ticket to be sold so i used just to perform both actions */}
      <TicketSaleAuthModal
        ticketData={freeTicketData}
        closeFunc={() => {
          setFreeTicketData("");
        }}
      />
      <VoucherModal
        openStatus={openVoucherModal}
        closeFunc={() => {
          setOpenVoucherModal(false);
        }}
        voucherTicketData={voucherTicketData}
      />
      <FilmDetailsModal
        openStatus={showFilmDetails}
        closeFunc={() => {
          setShowFilmDetails(false);
        }}
      />
      {allocationData && (
        <SeatAllocationModal
          allocationData={allocationData}
          closeFunc={() => {
            setAllocationData(false);
          }}
          addTicketToOrder={addTicketToOrder}
        />
      )}
      <QtyModalForAllocation
        ticketData={ticketDataForAllocation}
        closeFunc={() => {
          setTicketDataForAllocation(false);
        }}
        setAllocationData={setAllocationData}
        addTicketToOrder={addTicketToOrder}
      />
      <ViewsModal
        selectedView={selectedView}
        setSelectedView={setSelectedView}
        openStatus={showViewsModal}
        closeFunc={() => {
          setShowViewsModal(false);
        }}
      />
    </>
  );
};

export default Ticket;
