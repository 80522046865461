import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getFilmDetails,
  addToOrder,
  setOfflineRestriction
} from "../../../../redux/slices";
import { numToCurrency } from "../../../../utils/currencyHelper";
import { useTranslation } from "react-i18next";

const ListByScreens = ({
  NewTicketData,
  setShowFilmDetails,
  setTicketDataForAllocation,
  addTicketToOrder,
  start,
  end
}) => {
  const { t } = useTranslation(["Home"]);

  const dispatch = useDispatch();

  const { cinemaSettings, offlineMode } = useSelector(state => state.profile);

  const [paginatedTickets, setPaginatedTickets] = useState([]);

  useEffect(() => {
    if (NewTicketData?.length > 0) {
      setPaginatedTickets(
        NewTicketData.slice(start, end).map(x => {
          return { tickets: x.priceCard.tickets.slice(0, 7), isToggled: false };
        })
      );
    }
  }, [NewTicketData, paginatedTickets]);

  //This function helps to toggle the array of tickets in cases where there are more than 7 tickets
  const toggleFunc = index => {
    let newTicketList = [];

    //first check if the first half or second half is to be returned
    if (paginatedTickets[index].isToggled) {
      newTicketList = NewTicketData.slice(start, end)[
        index
      ].priceCard.tickets.slice(0, 7);
    } else {
      newTicketList = NewTicketData.slice(start, end)[
        index
      ].priceCard.tickets.slice(7, 14);
    }

    // format paginated tickets and substitute with newticketlist where applicable

    const newPaginatedData = paginatedTickets.map((x, i) => {
      if (i !== index) {
        return x;
      } else {
        return { tickets: newTicketList, isToggled: !x?.isToggled };
      }
    });

    setPaginatedTickets(newPaginatedData);
  };

  return (
    <div className="w-[93%]">
      <p className="text-center text-white py-1 text-[20px]">
        {" "}
        9:00 am - 10:00 am
      </p>

      <div className=" h-[100%]  p-2 grid grid-rows-6  ">
        {NewTicketData.slice(start, end)?.map((item, index) => {
          return (
            <div className=" w-[100%]  py-1 flex" key={item.id}>
              <div
                key={index}
                className="rounded p-2 bg-white flex w-[25%] h-[100%]"
                onClick={() => {
                  setShowFilmDetails(true);
                  dispatch(getFilmDetails(item?.filmId));
                }}
              >
                {cinemaSettings?.includePOSImages && (
                  <div className="w-[35%]">
                    <div
                      className="w-full h-full bg-no-repeat  bg-cover bg-center "
                      style={{
                        backgroundImage: `url(${item.posterUrl}) `
                      }}
                    ></div>
                  </div>
                )}
                <div
                  className={` ${
                    cinemaSettings?.includePOSImages ? "w-[65%]" : "w-[100%]"
                  } pl-1`}
                >
                  <div className="flex w-[100%]  ">
                    <p className="w-[80%] text-[12px] pr-1 truncate">
                      {item.film}
                    </p>
                    <p className="w-[20%] h-[20px] text-[10px] bg-primary p-1 flex justify-center items-center text-white rounded">
                      {/* {item.type} */}
                      xd
                    </p>
                  </div>
                  <p className="text-primary pt-1 text-[10px] ">{` ${new Date(
                    item.startTime
                  ).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit"
                  })} - ${new Date(item.endTime).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit"
                  })} `}</p>
                  <p className=" text-[10px] flex justify-between ">
                    <span>
                      <span>
                        {`${item.totalSeats}/${
                          item.seatsSold
                        }/${item.totalSeats - item.seatsSold}   `}{" "}
                      </span>{" "}
                      <span className="ml-1">{`${(
                        (item.seatsSold / item.totalSeats) *
                        100
                      ).toFixed(0)}%`}</span>{" "}
                    </span>
                    <span className="shadow px-1">
                      {item.screen || t("ticket.empty.screen")}
                    </span>
                  </p>
                </div>
              </div>
              <div className="w-[75%] grid grid-cols-8 grid-rows-1 border">
                {item.seatsSold < item.totalSeats &&
                  paginatedTickets[index]?.tickets?.map(pricecard => {
                    return (
                      <div
                        key={pricecard.id}
                        className="bg-white h-auto  rounded m-2 hover:bg-secondary hover:text-white flex justify-center items-center"
                        onClick={() => {
                          if (
                            cinemaSettings?.seatAllocation &&
                            pricecard.seatClassId
                          ) {
                            if (!offlineMode) {
                              setTicketDataForAllocation({
                                pricecard,
                                showtime: item
                              });
                              return;
                            } else {
                              dispatch(setOfflineRestriction(true));
                            }
                          } else {
                            addTicketToOrder({ pricecard, item });
                          }
                        }}
                      >
                        <div>
                          <p className="text-[10px] text-center">
                            {pricecard.shortName}
                          </p>
                          <p className="text-[10px] text-center">
                            {numToCurrency(pricecard.price)}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                {item.seatsSold >= item.totalSeats && (
                  <p className="text-white text-center text-[40px] col-span-8 my-auto">
                    {" "}
                    {t("ticket.finished")}
                  </p>
                )}
                {item.seatsSold < item.totalSeats &&
                  NewTicketData.slice(start, end)?.[index]?.priceCard.tickets
                    ?.length > 7 && (
                    <div
                      className="border-2 border[bg-secondary] h-auto  rounded m-2  text-white  flex justify-center items-center"
                      onClick={() => {
                        toggleFunc(index);
                      }}
                    >
                      <div>
                        {" "}
                        <p className="text-center">{t("ticket.more")}</p>
                        {!paginatedTickets[index]?.isToggled &&
                          NewTicketData.slice(start, end)?.[index]?.priceCard
                            .tickets?.length !== 8 && (
                            <p className="text-[10px] text-center">
                              {" "}
                              {`( 7 - ${
                                NewTicketData.slice(start, end)?.[index]
                                  ?.priceCard.tickets?.length
                              }  of ${
                                NewTicketData.slice(start, end)?.[index]
                                  ?.priceCard.tickets?.length
                              } )`}
                            </p>
                          )}
                        {paginatedTickets[index]?.isToggled && (
                          <p className="text-[10px] text-center">
                            {" "}
                            {`( 1 - 7  of ${
                              NewTicketData.slice(start, end)?.[index]
                                ?.priceCard.tickets?.length
                            } )`}
                          </p>
                        )}
                        {!paginatedTickets[index]?.isToggled &&
                          NewTicketData.slice(start, end)?.[index]?.priceCard
                            .tickets?.length === 8 && (
                            <p className="text-[10px] text-center">
                              {" "}
                              {`( 8 of ${
                                NewTicketData.slice(start, end)?.[index]
                                  ?.priceCard.tickets?.length
                              } )`}
                            </p>
                          )}
                      </div>
                    </div>
                  )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export { ListByScreens };
